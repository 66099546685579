import {
    CombinedFilterDescription,
    Contractor,
    FilterOperation,
    KoralmLocation,
    LatitudinalDirectedOpenLineConstruction,
    LatitudinalDirectedOutdoorConstructionWorkRegistration,
    LatitudinalDirectedTunnelWorkRegistration,
    Order,
    OutdoorWorkRegistration,
    RailNumber,
    ShiftSchemaDuringDay,
    Tube,
    TunnelObject,
    TunnelWorkRegistrationWithNumericLocationID,
    UniqueOpenLineConstruction,
    UniqueOutdoorConstructionWorkRegistration,
    ZoneBasedTunnelTubeWorkRegistration
} from "../generated";
import {FilterType} from "./FilterTypes";
import {contractorService} from "../services/contractorProvider";
import UserService from "../services/UserService";

export enum WebAppPage {
    ListView,
    WeekView,
    WorkRegistration,
}

export enum Roles {
    Coordinator = "Koordinator",
    ConstructionManager = "Bauleiter",
    Subcontractor = "Subunternehmer",
    Builder = "Bauherr",
}

export type TunnelWorkRegistration = TunnelWorkRegistrationWithNumericLocationID
    | LatitudinalDirectedTunnelWorkRegistration
    | ZoneBasedTunnelTubeWorkRegistration;

export type OutdoorWorkConstructions =
    UniqueOutdoorConstructionWorkRegistration
    | LatitudinalDirectedOutdoorConstructionWorkRegistration;
export type OutdoorWorkRegistrationsAndConstructions = OutdoorWorkRegistration | OutdoorWorkConstructions;

export type WorkRegistration = TunnelWorkRegistration | OutdoorWorkRegistrationsAndConstructions;
export type ObjectType = Tube | RailNumber | TunnelObject;
export type OutdoorBuildingTypes = LatitudinalDirectedOpenLineConstruction | UniqueOpenLineConstruction | "";

export type filterArgumentTypesWithCombinedFilterDescription = [number, number, string, Order, CombinedFilterDescription];

export type pageStateType = {
    isLoading: boolean;
    total: number;
    page: number;
    pageSize: number;
    sortingProperty: string;
    sortingValue: Order;
    filterProperty: string;
    filterValues: string[] | undefined;
    filterOperator: FilterOperation;
}

export type pageStateWithHeaderToolbarProps = pageStateType & {
    selectFilter: FilterType;
    selectedContractors: string[]
}

const extractTunnelObject = (object: any): TunnelObject | undefined => {
    if (Object.hasOwn(object.LocationDescription.ObjectDescription, 'TunnelObject')) {
        return object.LocationDescription.ObjectDescription.TunnelObject;
    }
};

export const getWorkRegistrationObjectTypeInRow = (object: any) => {
    const tunnelObject = extractTunnelObject(object.row);

    if (tunnelObject) {
        return tunnelObject;
    } else if (Object.hasOwn(object.row.LocationDescription, "RailNumber")) {
        if (Object.hasOwn(object.row, 'Construction')) {
            if (Object.hasOwn(object.row, "LatitudinalDirection")) {
                return LatitudinalDirectedOpenLineConstruction
            } else {
                return UniqueOpenLineConstruction
            }
        } else {
            return KoralmLocation.OpenLine;
        }
    } else {
        console.error("Cant find Object");
    }
}

export const getWorkRegistrationObjectType = (object: WorkRegistration) => {
    const tunnelObject = extractTunnelObject(object);
    if (tunnelObject) {
        return tunnelObject;
    } else if (Object.hasOwn(object.LocationDescription, "RailNumber")) {
        if (Object.hasOwn(object, 'Construction')) {
            if (Object.hasOwn(object, "LatitudinalDirection")) {
                return LatitudinalDirectedOpenLineConstruction
            } else {
                return UniqueOpenLineConstruction
            }
        } else {
            return KoralmLocation.OpenLine;
        }
    } else {
        console.error("Cant find Object");
    }
}

export function getEnumKeyByEnumValue<T extends { [index: string]: string }>(Enum: T, enumValue: string): string {
    let keys = Object.keys(Enum).find(key => Enum[key] === enumValue);
    return keys ? keys : "";
}

export function isCurrentUserOebbSub() {
    return contractorService.contractors.find(contractor => contractor.ID === UserService.tryGetCurrentContractorId())?.IsOEBB;
}

export function getOebbSubContractors() {
    return contractorService.contractors.filter(contractor => contractor.IsOEBB)
}

export function getMappedCompanies(): Contractor[] {
    const companiesFromKeycloak = UserService.tryGetMappedCompanies();
    if (companiesFromKeycloak === null || !companiesFromKeycloak) {
        return contractorService.contractors.filter(contractor => contractor.ID === UserService.getCompanyName());
    }

    return contractorService.contractors.filter(contractor =>
        companiesFromKeycloak.includes(contractor.ID)
    );
}

export const isContractorOebbSub = (contractorId: string) => getOebbSubContractors().some(contractor => contractor.ID === contractorId);

export const getShiftHours = (shift: ShiftSchemaDuringDay) => {
    switch (shift) {
        case ShiftSchemaDuringDay.Day:
            return {start: 6, end: 18};
        case ShiftSchemaDuringDay.Night:
            return {start: 18, end: 6};
        case ShiftSchemaDuringDay.DayAndNight:
            return {start: 6, end: 6};
        default:
            return {start: 6, end: 18};
    }
}