import UserService from "../services/UserService";
import {isContractorOebbSub, isCurrentUserOebbSub, Roles} from "./Types";
import {ProcessStatus} from "../generated";

const UserRoles = [Roles.Coordinator, Roles.ConstructionManager, Roles.Subcontractor];
const UserRolesWithBuilder = [...UserRoles, Roles.Builder]

class Universal {
    get pdfExport() {
        return UserService.hasRole(UserRolesWithBuilder);
    }

    get excelLeanBoardExport() {
        return UserService.hasRole(UserRolesWithBuilder);
    }

    get logbookExport() {
        return UserService.hasRole([Roles.Coordinator]);
    }

    get personCountExport() {
        return UserService.hasRole([Roles.Coordinator])
    }

    get exportCSV() {
        return UserService.hasRole(UserRolesWithBuilder);
    }

    get newWorkRegistrationButton() {
        return UserService.hasRole(UserRolesWithBuilder);
    }

    get logisticExport() {
        return UserService.hasRole([Roles.Coordinator]);
    }

    get combinedHistoryExport() {
        return UserService.hasRole([Roles.Coordinator])
    }

    get showInfoPopUp() {
        return UserService.hasRole([Roles.ConstructionManager, Roles.Subcontractor])
    }

    get showRejectedWorkRegistationsPopup() {
        return UserService.hasRole(UserRolesWithBuilder)
    }

    get showUnfinishedWorkRegistrationsDialog() {
        return UserService.hasRole(UserRoles)
    }

    get showMaterialLogisticsPopUp() {
        return UserService.hasRole(UserRolesWithBuilder)
    }

    get getContractorsOrCompanies() {
        return UserService.hasRole([Roles.ConstructionManager])
    }

    get showMaterialPopUpContractor() {
        return UserService.hasRole([Roles.Coordinator, Roles.ConstructionManager])
    }

    get showWeekProgramIbnSelection() {
        return UserService.hasRole([Roles.Coordinator, Roles.Builder])
    }

    get showThirdLevelOutlineEditor() {
        return UserService.hasRole([Roles.Coordinator])
    }

    get showContractorEditor() {
        return UserService.hasRole([Roles.Coordinator])
    }

    get ibnSelectionName() {
        return UserService.hasRole([Roles.Builder])
    }
}

class ListView {
    get areaRestriction() {
        return UserService.hasRole(UserRolesWithBuilder);
    }

    get dropdownList() {
        return UserService.hasRole(UserRolesWithBuilder);
    }

    get sorting() {
        return UserService.hasRole(UserRolesWithBuilder);
    }

    get filtering() {
        return UserService.hasRole(UserRolesWithBuilder);
    }

    deleting(contractorId: string) {
        return this.isPermittedOrNotContractor(contractorId);
    }

    canEditCurrentLine(params: any) {
        return this.editButton(params.row.ContractorId);
    }

    canEditMaterialLogisticContractor() {
        return UserService.hasRole([Roles.Coordinator])
    }

    editButton(contractorId: string) {
        return this.isPermittedOrNotContractor(contractorId);
    }

    quickThirdLevelOutlineButton(contractorId: string) {
        return this.editButton(contractorId);
    }

    quickIsMilestoneButton() {
        return UserService.hasRole([Roles.Coordinator, Roles.Builder])
    }

    quickWeekProgramButton() {
        return UserService.hasRole([Roles.Coordinator])
    }

    showUuId() {
        return UserService.hasRole([Roles.Coordinator])
    }

    autoApprovedStatusChange() {
        return UserService.hasRole([Roles.Coordinator, Roles.ConstructionManager])
    }

    private isPermittedOrNotContractor(contractorId: string) {
        const currentContractor = UserService.tryGetCurrentContractorId();
        const mappedCompanies = UserService.tryGetMappedCompanies();
        if (UserService.hasRole([Roles.Builder])) {
            return isContractorOebbSub(contractorId);
        } else if (currentContractor) {
            return currentContractor === contractorId;
        } else if (mappedCompanies) {
            return mappedCompanies.includes(contractorId);
        }
        return UserService.hasRole([Roles.Coordinator, Roles.ConstructionManager]);
    }

    get newPage() {
        return UserService.hasRole(UserRolesWithBuilder);
    }
}

class Overview {
    get startWeek() {
        return UserService.hasRole(UserRoles);
    }

    get newStop() {
        return UserService.hasRole([Roles.Coordinator])
    }
}

class NewWorkRegistration {
    isPermissionGranted(processStatus: ProcessStatus): boolean {
        const permissionMap = {
            [ProcessStatus.TaskCreatedByPlaner]: [Roles.Coordinator],
            [ProcessStatus.ChangeSubmitted]: UserRolesWithBuilder,
            [ProcessStatus.ChangeApproved]: [Roles.Coordinator, Roles.ConstructionManager],
            [ProcessStatus.ChangeRejected]: [Roles.Coordinator, Roles.ConstructionManager],
            [ProcessStatus.Done]: UserRolesWithBuilder
        };

        return UserService.hasRole(permissionMap[processStatus] || []);
    }

    get companyAuto() {
        return UserService.hasRole([Roles.Subcontractor]);
    }

    get companyFreeSelection() {
        return UserService.hasRole([Roles.ConstructionManager, Roles.Coordinator, Roles.Builder]);
    }

    get limitToOebbCompanySelection() {
        return UserService.hasRole([Roles.Builder]) || isCurrentUserOebbSub()
    }


    get limitToMappedCompanies() {
        return UserService.hasRole([Roles.ConstructionManager])
    }

    get workingStep() {
        return UserService.hasRole(UserRolesWithBuilder);
    }

    get notes() {
        return UserService.hasRole(UserRolesWithBuilder);
    }

    get safeChanges() {
        return UserService.hasRole(UserRolesWithBuilder);
    }

    get dayShift() {
        return UserService.hasRole(UserRolesWithBuilder);
    }

    get nightShift() {
        return UserService.hasRole(UserRolesWithBuilder);
    }

    get dayAndNightShift() {
        return UserService.hasRole(UserRolesWithBuilder);
    }

    get continuesDuringWeekend() {
        return UserService.hasRole(UserRolesWithBuilder)
    }

    get partOfWeekProgram() {
        return UserService.hasRole([Roles.Coordinator])
    }

    get isMilestone() {
        return UserService.hasRole([Roles.Coordinator, Roles.Builder])
    }

    get autoPartOfWeekProgram() {
        return UserService.hasRole([Roles.Builder]) || isCurrentUserOebbSub()
    }

    get personCount() {
        return UserService.hasRole(UserRolesWithBuilder)
    }

    get tunnel() {
        return UserService.hasRole(UserRolesWithBuilder);
    }

    get openLine() {
        return UserService.hasRole(UserRolesWithBuilder);
    }

    get objects() {
        return UserService.hasRole(UserRolesWithBuilder);
    }

    get areaFrom() {
        return UserService.hasRole(UserRolesWithBuilder);
    }

    get areaTo() {
        return UserService.hasRole(UserRolesWithBuilder);
    }

    get direction() {
        return UserService.hasRole(UserRolesWithBuilder);
    }

    get railTrackStatus() {
        return UserService.hasRole(UserRolesWithBuilder);
    }

}

class WorkRegistrationEditing {
    isPermissionGranted(processStatus: ProcessStatus): boolean {
        const permissionMap = {
            [ProcessStatus.TaskCreatedByPlaner]: [],
            [ProcessStatus.ChangeSubmitted]: UserRolesWithBuilder,
            [ProcessStatus.ChangeApproved]: [Roles.Coordinator, Roles.ConstructionManager],
            [ProcessStatus.ChangeRejected]: [Roles.Coordinator, Roles.ConstructionManager],
            [ProcessStatus.Done]: UserRolesWithBuilder
        };

        return UserService.hasRole(permissionMap[processStatus] || []);
    }

    changeThirdLevelOutline() {
        return UserService.hasRole(UserRolesWithBuilder)
    }

    get companyFreeSelection() {
        return UserService.hasRole([Roles.Coordinator])
    }

    get workingStep() {
        return UserService.hasRole(UserRolesWithBuilder);
    }

    get notes() {
        return UserService.hasRole(UserRolesWithBuilder);
    }

    get safeChanges() {
        return UserService.hasRole(UserRolesWithBuilder);
    }

    get dayShift() {
        return UserService.hasRole([Roles.Coordinator, Roles.ConstructionManager, Roles.Builder]);
    }

    get nightShift() {
        return UserService.hasRole([Roles.Coordinator, Roles.ConstructionManager, Roles.Builder]);
    }

    get dayAndNightShift() {
        return UserService.hasRole([Roles.Coordinator, Roles.ConstructionManager, Roles.Builder]);
    }

    get continuesDuringWeekend() {
        return UserService.hasRole(UserRolesWithBuilder)
    }

    get partOfWeekProgram() {
        return UserService.hasRole([Roles.Coordinator])
    }

    get isMilestone() {
        return UserService.hasRole([Roles.Coordinator, Roles.Builder])
    }

    get personCount() {
        return UserService.hasRole(UserRolesWithBuilder)
    }

    get tunnel() {
        return UserService.hasRole([Roles.Coordinator, Roles.Builder]);
    }

    get openLine() {
        return UserService.hasRole([Roles.Coordinator, Roles.Builder]);
    }

    get objects() {
        return UserService.hasRole([Roles.Coordinator, Roles.Builder]);
    }

    get areaFrom() {
        return UserService.hasRole(UserRolesWithBuilder);
    }

    get areaTo() {
        return UserService.hasRole(UserRolesWithBuilder);
    }

    get direction() {
        return UserService.hasRole(UserRolesWithBuilder);
    }

    get railTrackStatus() {
        return UserService.hasRole(UserRolesWithBuilder);
    }
}

export class PermissionManager {
    static Universal = new Universal();
    static ListView = new ListView();
    static Overview = new Overview();
    static NewWorkRegistration = new NewWorkRegistration();
    static WorkRegistrationEditing = new WorkRegistrationEditing();
}
